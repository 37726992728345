<script setup lang="ts">
import TheIcon from '@/Components/TheIcon.vue'
import { Autosuggestion, AutosuggestionItem } from '@/Pages/the-home/interfaces/Search'

defineProps<{
    suggestions: Autosuggestion[]
    doAnimation?: boolean
}>()

const emit = defineEmits<{
    selectPlace: [value: AutosuggestionItem]
}>()

</script>

<template>
    <div class="autocomplete-suggestions" :class="{'show-dropdown': !doAnimation}">
        <div v-for="(group, index) in suggestions" :key="index">
            <div class="autocomplete-group">
                {{ group.category.toUpperCase() }}
            </div>

            <div
                v-for="(item, idx) in group.items"
                :key="`place_${ idx }`"
                class="autocomplete-suggestion autocomplete-selected cursor-pointer"
                @click="emit('selectPlace', item)"
            >
                <img
                    v-if="item.img"
                    :src="item.img"
                    class="autocomplete-img"
                    :alt="item.query"
                >

                <TheIcon
                    v-else
                    icon-id="map-place"
                    width="24"
                    height="25"
                />

                <span v-html="item.highlighted" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
#heroSearch .show-dropdown {
    @media (min-width: 992px) {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        padding: 0 32px;
    }  
}
</style>